@font-face{
  font-family: 'Airwaves';
  src: local('Airwaves'), url('../theme/fonts/Airwaves-Regular.ttf') format('truetype')
}

@font-face{
  font-family: 'AvenirNextCondensed';
  src: local('AvenirNextCondensed'), url('../theme/fonts/AvenirNextLTPro-Condensed.ttf') format('truetype')
}

.Box-gradient {
  background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #f0372b, #8e110e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #f0372b, #8e110e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
  .Box-title1 {
    color: white;
    background-image: url('/src/assets/homeBackground1.png');
    background-attachment: fixed;
    background-size: 'cover';
    background-repeat: no-repeat;
  }

  .font-family-Airwaves {
    font-family: Airwaves;
  }

  .font-family-AvenirNextCondensed {
    font-family: AvenirNextCondensed;
  }

@media (max-width: 1000px) {
  .title1 {
    font-size: 36px;
  }
  .title2 {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 380px) {
  .title1 {
    font-size: 28px;
  }
  .title2 {
    font-size: 28px;
  }
}
